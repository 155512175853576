import React from 'react';

import { Container, UnderDevelopment } from './styles';
import Header from '../../components/Header';

export default function Dashboard() {
  return (
    <Container>
      <Header />
      <UnderDevelopment>
        <h1>This site is under development</h1>
      </UnderDevelopment>
    </Container>
  );
}
