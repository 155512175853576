import React from 'react';
import { FaGithubAlt, FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';

export default [
  {
    url: 'http://github.com/matgsan',
    icon: <FaGithubAlt />,
  },
  {
    url: 'http://linkedin.com/in/matgsan',
    icon: <FaLinkedin />,
  },
  {
    url: 'http://twitter.com/matgsan',
    icon: <FaTwitter />,
  },
  {
    url: 'mailto:me@matgsan.dev',
    icon: <FaEnvelope />,
  },
];
