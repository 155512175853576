import styled, { keyframes } from 'styled-components';
import { slideInDown, slideInLeft } from 'react-animations';
import { darken } from 'polished';
import avatar from '~/assets/avatar_sem_fundo.png';

const slideInDownAnimation = keyframes`${slideInDown}`;
const slideInLeftAnimation = keyframes`${slideInLeft}`;

export const Container = styled.header`
  animation: 3s ${slideInDownAnimation};
  padding: 3rem;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;
export const About = styled.div`
  animation: 3s ${slideInLeftAnimation};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 720px) {
    align-items: center;
    margin-bottom: 3rem;
  }
  div {
    h1 {
      font-size: 3.8rem;
      font-weight: 700;
    }
    h2 {
      color: #778492;
      font-weight: 400;
      font-size: 2.4rem;
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 720px) {
      text-align: center;
    }
  }
`;

export const Avatar = styled.img.attrs({
  src: avatar,
})`
  border-radius: 50%;
  margin-right: 3rem;
  width: auto;
  max-height: 25rem;

  @media (max-width: 720px) {
    margin-right: 0;
  }
`;

export const SocialList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin-left: -0.5rem;

  li {
    padding: 0 0.5rem;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.6rem;
      height: 3.6rem;
      background: #b0b7bf;
      border-radius: 50%;
      transition: 0.5s background;
      color: #fff;
      &:hover {
        background: ${darken(0.3, '#b0b7bf')};
      }
      svg {
        font-size: 2rem;
      }
    }
  }
`;

export const ContactMe = styled.div`
  display: flex;
  align-items: center;
  a {
    background: #54ba4e;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    padding: 1rem 1.8rem;
    border-radius: 0.4rem;
    svg {
      margin-right: 0.5rem;
    }
  }
`;
