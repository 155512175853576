import React from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import Social from '~/config/social';

import {
  Container,
  MainContent,
  Avatar,
  About,
  SocialList,
  ContactMe,
} from './styles';

export default function Header() {
  return (
    <Container>
      <MainContent>
        <Avatar alt="Matheus Santos" />
        <About>
          <div>
            <h1>Matheus Santos</h1>
            <h2>Matgsan</h2>
          </div>
          <SocialList>
            {Social.map((element, index) => (
              <li index={index} key={String(index)}>
                <a href={element.url}>{element.icon}</a>
              </li>
            ))}
          </SocialList>
        </About>
      </MainContent>
      <ContactMe>
        <a href="mailto:me@matgsan.dev">
          <FaPaperPlane />
          Contact Me
        </a>
      </ContactMe>
    </Container>
  );
}
