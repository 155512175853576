import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  html, body {
    height: 100%;
    background: #DAE3E7;
    color: #49515a;
  }
  html {
    font-size: 62.5%;
  }
  @media (max-width: 1080px) {
    html {
    font-size: 58%;
    }
  }
  @media (max-width: 720px) {
    html { 
      font-size: 54%;
    }
  }
   #root {
    min-height: 100%;
  }
  body {
    -webkit-font-smoothing: antialiased
  }
  body, input, button {
    font: 1.4rem 'Roboto', sans-serif;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  button{
    cursor: pointer;
  }
`;
